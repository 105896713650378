/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useContext } from "react"
import {graphql} from 'gatsby'
import Base from "../components/templates/Base"
import Content from "../components/particles/Content"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { css } from '@emotion/react'
import { useBranchData } from "../components/particles/hooks/useBranchData"
import theme from "../components/particles/Theme"
import { randomID, formatPhoneNumber } from "../components/particles/helpers"
import * as Yup from "yup"
import BackgroundImage from "gatsby-background-image"
import hexToRgba from "hex-to-rgba"
import SubHeading from "../components/atoms/SubHeading/SubHeading"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { convertToBgImage } from "gbimage-bridge";

const StyledContact = css`
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  padding: 10rem;
  min-height: 100vh;

  @media (max-width: ${theme.breakpoints[0]}) {
    padding: 0;
  }

  .float-left {
    float: left;
    width: 45%;

    @media (max-width: ${theme.breakpoints[1]}) {
      float: none;
      width: 100%;
      text-align: center;
    }

    @media (max-width: ${theme.breakpoints[0]}) {
      padding-top: 5rem;
    }

    h3,
    h4 {
      &:first-of-type {
        margin-top: 3rem;
      }
      color: white;

      @media (max-width: ${theme.breakpoints[1]}) {
      }
    }
  }

  .clear {
    clear: both;
  }

  label {
    width: 45%;
    span {
      color: red;
    }

    &.full-width {
      width: calc(100% + 1rem);
      margin-left: 0;
    }
  }

  fieldset {
    margin-bottom: 3rem;
  }

  label {
    display: inline-block;
    margin: 1rem;
  }

  input {
    display: block;
    width: calc(100% - 2rem);
  }

  button {
    background: ${theme.colors.primary};
    border-radius: 5px;
    color: white;
    padding: 1.5rem 3rem;
    font-weight: 700;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    width: 100%;

    &:hover {
      background: ${theme.colors.primaryHover};
    }

    &:disabled {
      background: ${theme.colors.tertiaryHover};
      border-bottom: 3px solid ${theme.colors.tertiary};
      border-top: 3px solid ${theme.colors.tertiary};
      border-right: 3px solid ${theme.colors.tertiary};
      border-left: 3px solid ${theme.colors.tertiary};

      cursor: not-allowed;
    }
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    min-width: calc(100% - 1rem);
    position: relative;

    background: linear-gradient(45deg, transparent 45%, #666 50%),
      linear-gradient(135deg, #666 45%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 21px) calc(1em),
      calc(100% - 16px) calc(1em), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
  }

  input,
  select,
  textarea {
    background-color: ${theme.colors.tertiary};
    border: 0;
    padding: 1rem;
  }

  textarea {
    display: block;
    width: calc(100% - 3rem);
    min-height: 150px;
    resize: vertical;
    border-radius: 5px;
    margin-bottom: 2rem;
  }

  form {
    width: 600px;
    float: right;
    border-radius: 5px;
    background: white;
    padding: 5rem 2rem;
    box-shadow: 5px 5px 15px ${theme.colors.secondary};

    @media (max-width: ${theme.breakpoints[1]}) {
      float: none;
      clear: both;
      margin: 0 auto;
      margin-top: 5rem;
      width: 100%;
    }

    @media (max-width: ${theme.breakpoints[0]}) {
      position: relative;
      right: 4rem;
      width: calc(100% + 4rem);
      border-radius: 0;
    }
  }
`

const ContactUsPage = ({ data, location }) => {
  const globalBranch = useContext(GlobalStateContext)
  let branches = useBranchData()
  let options = branches
    .sort((a, b) => (a.node.title > b.node.title ? 1 : -1))
    .map(branch => ({
      label: branch.node.title,
      value: branch.node.title,
    }))

  const backgroundStack = [
    data.file.childImageSharp.gatsbyImageData,
    `linear-gradient(to bottom right, ${hexToRgba(
      theme.colors.primaryHover,
      0.75
    )}, ${hexToRgba(theme.colors.secondaryHover, 0.75)})`,
  ].reverse()

  const bgImage = convertToBgImage(backgroundStack);

  const renderBranchContact = branch => {
    if (branch === undefined) return null

    const {
      streetNumber,
      streetName,
      city,
      stateShort,
      postCode,
    } = branch.node.Branch.branchAddress
    return (
      <>
        <h4>
          {streetNumber} {streetName}
          <br />
          {city}, {stateShort}, {postCode}
        </h4>
        <h4>Phone: {formatPhoneNumber(branch.node.Branch.phoneNumber)}</h4>
        <h4>Fax: {formatPhoneNumber(branch.node.Branch.faxNumber)}</h4>
      </>
    )
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  let multifamily = null

  if (typeof window !== `undefined`) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    multifamily = urlParams.get('multifamily')
  }

  return (
    <Base title="Contact Us">
      <BackgroundImage {...bgImage} css={StyledContact}>
        <Content>
          <Formik
            initialValues={{
              fullName: "",
              company: "",
              jobTitle: "",
              email: "",
              branch:
                location.state && location.state.branch
                  ? location.state.branch
                  : globalBranch && ( globalBranch.branch === "All Branches" || multifamily )
                  ? "Corporate"
                  : globalBranch && globalBranch.branch,
              companyEmail:
                (location.state && location.state.email) || "info@dixieply.com",
              representative:
                location.state && location.state.contact
                  ? location.state.contact
                  : multifamily
                  ? `Multifamily Representative`
                  : `general`,
              message: "",
            }}
            validationSchema={Yup.object({
              fullName: Yup.string()
                .max(50, "Must be 50 characters or less")
                .required("Required"),
              company: Yup.string()
                .max(50, "Must be 50 characters or less")
                .required("Required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              customerClassification: Yup.string()
                .test('len', 'Please select an option', val => val) // only shows if change and change back
                .required("Required"), // required doesnt do anything
              confirmCheckbox: Yup.bool() // only shows if change and change back
                .oneOf([true], 'Please check the agreement')
                .required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (values.branch !== "Corporate") {
                branches.forEach(branch => {
                  if (values.branch === branch.node.title) {
                    values.representative === "general"
                      ? (values.companyEmail = branch.node.Branch.generalEmail)
                      : branch.node.Branch.contacts.forEach(contact => {
                          if ( values.representative === `${contact.name} - ${contact.title}` ) {
                            values.companyEmail = contact.email
                          }
                      }) || branch.node.Branch.specReps.forEach(rep => {
                        if (values.representative === `${rep.name} - Specification Representative`) {
                          values.companyEmail = rep.email
                        }
                      })
                  }
                })
              } else if ( values.representative === "Multifamily Representative") {
                values.companyEmail = "mccurda@dixieply.com"
              } else {
                values.companyEmail = "info@dixieply.com"
              }

              fetch("/?no-cache=1", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({
                  "form-name": "Contact Form",
                  ...values,
                }),
              })
                .then(() => {
                  alert("Success!")
                  setSubmitting(false)
                })
                .catch(error => alert(error))
            }}
          >
            {({ isSubmitting, values, handleSubmit }) => (
              <>
                <section className="float-left">
                  <SubHeading title={`Contact Us - ${values.branch}`} dark />
                  {values.branch === "Corporate" ? (
                    <>
                      <h4>
                        P.O. Box 2328
                        <br />
                        Savannah, GA 31402-2328
                      </h4>
                      <h4>Phone: {formatPhoneNumber(`9124477000`)}</h4>
                      <h4>Fax: {formatPhoneNumber(`9124477039`)}</h4>
                    </>
                  ) : (
                    <>
                      {renderBranchContact(
                        branches.find(e => e.node.title === values.branch)
                      )}
                    </>
                  )}
                </section>
                <Form
                  name="Contact Form"
                  onSubmit={handleSubmit}
                  data-netlify="true"
                >
                  <fieldset>
                    <legend>Your Information</legend>
                    <label htmlFor="fullName">
                      Full Name:{" "}
                      <span>
                        <ErrorMessage name="fullName" />
                      </span>
                      <Field name="fullName" type="text" />
                    </label>

                    <label htmlFor="company">
                      Company:{" "}
                      <span>
                        <ErrorMessage name="company" />
                      </span>
                      <Field name="company" type="text" />
                    </label>

                    <label htmlFor="jobTitle">
                      Job Title/Position:{" "}
                      <span>
                        <ErrorMessage name="jobTitle" />
                      </span>
                      <Field name="jobTitle" type="text" />
                    </label>

                    <label htmlFor="email">
                      Email:{" "}
                      <span>
                        <ErrorMessage name="email" />
                      </span>
                      <Field name="email" type="email" />
                    </label>
                  </fieldset>
                  <label htmlFor="branch" className="full-width">
                    Branch:{" "}
                    <Field name="branch" id="branch" as="select">
                      <option value="Corporate">Corporate</option>
                      {options.map(option => (
                        <option value={option.value} key={randomID()}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                  </label>

                  <label htmlFor="representative" className="full-width">
                    Branch Contact:
                    <Field
                      name="representative"
                      as="select"
                      id="contact"
                    >
                      <option value="general">General</option>
                      {values.branch !== "Corporate" ? (
                        <>
                          {
                          branches.find(e => e.node.title === values.branch) &&
                          branches
                            .find(e => e.node.title === values.branch)
                            .node.Branch.contacts.map(contact => (
                              <option
                                value={contact.name + " - " + contact.title}
                                key={randomID()}
                              >
                                {contact.name + " - " + contact.title}
                              </option>
                            ))
                          }
                          {
                            branches.find(e => e.node.title === values.branch) &&
                            branches
                              .find(e => e.node.title === values.branch)
                              .node.Branch.specReps &&
                            branches
                              .find(e => e.node.title === values.branch)
                              .node.Branch.specReps.map(rep => (
                                <option
                                value={rep.name + " - Specification Representative"}
                                key={randomID()}
                              >
                                  {rep.name + " - Specification Representative"}
                              </option>
                              ))
                          }
                        </>
                      ) : (
                        <>
                          <option value="Human Resources">
                            Human Resources
                          </option>
                          <option value="LEED Specialists">
                            LEED Specialists
                          </option>
                          <option value="Purchasing Department">
                            Purchasing Department
                          </option>
                          <option value="Multifamily Representative">
                              Mike Curda - Multifamily Representative
                          </option>
                        </>
                      )}
                    </Field>
                  </label>

                  <label htmlFor="customerClassification" className="full-width">
                    Customer Classification:{" "}
                    <span>
                      <ErrorMessage name="customerClassification" />
                    </span>
                    <Field
                      name="customerClassification"
                      as="select"
                    >
                      <option value="">
                          Select a classification...
                      </option>
                      <option value="Building Materials retailer/dealer">
                          Building Materials retailer/dealer
                      </option>
                      <option value="Fabricator - cabinets, closets, store fixtures, etc.">
                        Fabricator - cabinets, closets, store fixtures, etc.
                      </option>
                      <option value="Government Agency">
                        Government Agency
                      </option>
                      <option value="Architect/Designer">
                        Architect/Designer
                      </option>
                      <option value="General Contractor or end user">
                        General Contractor or end user
                      </option>
                    </Field>
                  </label>

                  <label htmlFor="message" className="full-width">
                    Questions or Comments:
                    <Field name="message" as="textarea" />
                  </label>

                  <span>
                    <ErrorMessage name="confirmCheckbox" />
                  </span>
                  <label htmlFor="confirmCheckbox" className="full-width">
                    <Field type="checkbox" name="confirmCheckbox" style={{width: "fit-content", marginRight: "6px", display: "inline"}} />
                    I UNDERSTAND THAT DIXIEPLY IS A WHOLESALE DISTRIBUTOR ONLY AND AS SUCH, DOES NOT SELL TO CONTRACTORS OR THE GENERAL PUBLIC.<br />
                    We will be happy to refer you to one of our dealers who can help you get what you need.
                  </label>

                  <Field name="companyEmail" id="companyEmail" type="hidden" />
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </Form>
              </>
            )}
          </Formik>
          <div className="clear" />
        </Content>
      </BackgroundImage>
    </Base>
  )
}

export const query = graphql`query getContactImage {
  file(relativePath: {eq: "contact-us.jpg"}) {
    id
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
}
`

export default ContactUsPage
